import { faDollarSign, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Radio, Switch } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment/moment";
import React, { Fragment, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/common/Input";
import ReactSelect from "../../components/common/ReactSelect";
import {
  ManageDeleteProductIcon,
  ProfileUploadSvg,
} from "../../components/svgs";
import Layout from "../../layout";
import {
  getAllDeviceRequest,
  updateAllDeviceRequest,
} from "../../store/settings/device/actions";
import * as Yup from "yup";

import { components } from "react-select";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { getUserOrgLocationSetting } from "../../utils/utils";
// Custom option component
const CustomOption = (props) => {
  const orgDetails = getUserOrgLocationSetting();
  return (
    <components.Option {...props}>
      <span>
        {orgDetails?.currency?.symbol}
        {props.data.label}
      </span>
    </components.Option>
  );
};

// Custom single value component
const CustomSingleValue = (props) => {
  const orgDetails = getUserOrgLocationSetting();
  return (
    <components.SingleValue {...props}>
      {orgDetails?.currency?.symbol}
      {props.data.label}
    </components.SingleValue>
  );
};

const AllDeviceSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const {
    isLoading,
    getDeviceData,
    dateFormatList,
    currencyList,
    numberFormats,
    minRedeemablePoints,
    pointsRedeemableDelay,
    valueOfLoyaltyPoints,
    isUpdateLoading,
  } = useSelector((store) => ({
    isLoading: store?.device?.getAllDeviceData?.loading,
    getDeviceData: store?.device?.getAllDeviceData?.data,
    dateFormatList: store?.setting?.dateFormatList,
    currencyList: store?.setting?.currencyList,
    numberFormats: store?.setting?.numberFormats,
    minRedeemablePoints: store?.setting?.minRedeemablePoints,
    pointsRedeemableDelay: store?.setting?.pointsRedeemableDelay,
    valueOfLoyaltyPoints: store?.setting?.valueOfLoyaltyPoints,
    isUpdateLoading: store?.device?.updateAllDeviceData?.loading,
  }));

  console.log(minRedeemablePoints, "minRedeemablePoints")

  const [uploadedImage, setUploadedImage] = useState(null);

  const handleDeleteImageUpload = (setFieldValue) => {
    if (imageRef.current) {
      imageRef.current.value = "";
    }
    const fileInput = document.getElementById("upload-image");
    if (fileInput) fileInput.value = ""; // Clear the value of the input field for web
    setFieldValue("image", null);
    setUploadedImage(null);
  };

  useLayoutEffect(() => {
    dispatch(getAllDeviceRequest());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (getDeviceData) {
      setUploadedImage(getDeviceData?.orgData?.image);
    }
  }, [getDeviceData]);

  return (
    <Layout>
      {isLoading && (
        <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
          <Loader />
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          image: getDeviceData?.orgData?.image || null,
          email: getDeviceData?.orgData?.email || "",
          name: getDeviceData?.orgData?.name || "",
          footer: getDeviceData?.orgData?.footer || "",
          date_format: getDeviceData
            ? {
              label: moment().format(
                getDeviceData?.settingData?.date_format?.date_format
              ),
              value: getDeviceData?.settingData?.date_format?._id,
            }
            : "",
          number_format: getDeviceData?.settingData?.number_format || "",
          currency: getDeviceData?.settingData?.currency || "",
          is_symbol_before:
            getDeviceData?.settingData?.is_symbol_before || false,
          loyalty_point_enable:
            getDeviceData?.settingData?.loyalty_point_enable || false,
          min_redeemable_points:
            getDeviceData?.settingData?.min_redeemable_points || 100,
          points_redeemable_delay:
            getDeviceData?.settingData?.points_redeemable_delay || "",
          value_of_loyalty_points:
            {
              label: getDeviceData?.settingData?.value_of_loyalty_points,
              value: getDeviceData?.settingData?.value_of_loyalty_points,
            } || "",
        }}
        validationSchema={Yup.object().shape({
          // image: Yup.mixed().required(t("Image is required")),
          email: Yup.string()

            .email(t("Please enter your valid email"))
            .required(t("Email is required")),
          name: Yup.string().required(t("Name is required")),
        })}
        onSubmit={(values) => {
          const formData = new FormData();
          const orgData = {
            email: values.email,
            name: values.name,
            footer: values.footer,
          };
          const settingData = {
            date_format: values.date_format.value,
            number_format: values.number_format,
            currency: values.currency._id,
            is_symbol_before: values.is_symbol_before,
            loyalty_point_enable: values.loyalty_point_enable,
            min_redeemable_points: Number(values.min_redeemable_points),
            points_redeemable_delay: values.points_redeemable_delay,
            value_of_loyalty_points: Number(
              values.value_of_loyalty_points.value
            ),
          };
          values.image && typeof values.image === "object" &&
            formData.append("image", values.image);
          formData.append("orgDetails", JSON.stringify(orgData));
          formData.append("settingDetails", JSON.stringify(settingData));
          dispatch(
            updateAllDeviceRequest({
              id: getDeviceData?.settingData?._id,
              body: formData,
            })
          );
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isValid,
          dirty,
        }) => (
          console.log(isValid, "isValid"),
          console.log(dirty, "dirty"),
          console.log(values, "values"),
          <Form className="grid grid-cols-7 gap-2.5" onSubmit={handleSubmit}>
            <div className="2xl:col-span-2 col-span-3 bg-white rounded-theme  xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)] p-5 overflow-y-auto">
              <h2 className="pb-3 text-lg font-semibold text-info-500 lg:text-xl">
                {t("Receipts")}
              </h2>
              <p className="pb-5 xl:text-lg lg:text-base_24 text-sm_18 text-secondary-500">
                {t("Organization Logo For Receipts")}
              </p>
              <div className="2xl:h-[357px] lg:h-[300px] h-40 relative overflow-hidden bg-secondary-50 flex flex-col gap-y-10 items-center justify-center rounded-theme border-2 border-secondary-200/50">
                {uploadedImage ? (
                  <Fragment>
                    <img
                      src={uploadedImage}
                      alt="user-profile"
                      className="object-cover w-full h-full"
                    />
                    <IconButton
                      className="bg-white !absolute top-2 right-2 rounded-base text-red-500"
                      onClick={() => handleDeleteImageUpload(setFieldValue)}
                    >
                      {/* <FontAwesomeIcon icon={faTrashAlt} fontSize={18} /> */}
                      <ManageDeleteProductIcon className="w-4 h-4" />
                    </IconButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ProfileUploadSvg width={"93"} height="93" />
                    <label
                      htmlFor="upload-image"
                      className="px-3 py-2 font-semibold text-center border border-dashed cursor-pointer xl:text-xl lg:text-base text-sm_18 border-secondary-500 text-secondary-500 xl:py-4 lg:py-3 lg:px-10 rounded-theme"
                    >
                      {t("Upload Image")}
                    </label>
                    <input
                      type="file"
                      id="upload-image"
                      ref={imageRef}
                      className="absolute opacity-0"
                      onChange={(event) => {
                        if (event.target.files.length > 0) {
                          setFieldValue("image", event.target.files[0]);
                          setUploadedImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                    />
                  </Fragment>
                )}
              </div>
              <ErrorMessage
                name="image"
                component={"p"}
                className="text-sm text-red-500"
              />

              <div className="xl:pt-[30px] lg:pt-6 pt-5">
                <Input
                  type={"email"}
                  label={t("From email address")}
                  labelProps={"text-secondary-500 font-medium capitalize"}
                  className={"bg-secondary-50 text-info-500 !font-medium"}
                  placeholder="sales@example.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="email"
                  component={"p"}
                  className="text-sm text-red-500"
                />
              </div>
              <div className="xl:py-[30px] lg:py-6 py-5">
                <label className="block pb-1 text-base font-medium xl:text-base text-secondary-500">
                  {t("Footer")}
                </label>
                <textarea
                  type={"text"}
                  name="footer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.footer}
                  className="outline-none lg:h-48 h-40 resize-none bg-secondary-50 text-info-500 font-medium w-full placeholder:text-secondary-500 border p-2.5 rounded-theme border-secondary-200 focus:border-primary-400 focus:shadow transition-all duration-200"
                ></textarea>
              </div>
            </div>

            <div className="2xl:col-span-5 col-span-4 overflow-y-auto xl:h-[calc(100vh-116px)] h-[calc(100vh-90px)]">
              <div className="bg-white rounded-theme lg:py-5 lg:px-6 p-4 mb-[14px]">
                <h2 className="pb-4 text-lg font-semibold text-info-500 lg:text-xl xl:pb-6">
                  {t("Organization Setting")}
                </h2>
                <div className="lg:mb-6 mb-4 max-w-[559px]">
                  <Input
                    type={"text"}
                    label={t("Organization Name")}
                    labelProps={"text-secondary-500 font-medium capitalize"}
                    className={"bg-secondary-50 text-info-500 !font-medium"}
                    placeholder=""
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="name"
                    component={"p"}
                    className="text-sm text-red-500"
                  />
                </div>
                <div className="lg:mb-6 mb-4 max-w-[560px]">
                  <ReactSelect
                    label={t("Date Format")}
                    labelProps={
                      " text-secondary-500 !font-medium !text-base capitalize"
                    }
                    options={dateFormatList?.map((item) => ({
                      label: moment().format(item.date_format),
                      value: item._id,
                    }))}
                    value={values.date_format}
                    name="date_format"
                    onChange={(data) => setFieldValue("date_format", data)}
                  />
                </div>
                <div className="xl:mb-6 max-w-[560px]">
                  <p className="block text-base xl:text-base  text-secondary-500 pb-3.5 font-medium">
                    {t("Number Format")}
                  </p>
                  <div className="flex items-center flex-wrap 2xl:gap-x-[78px] lg:gap-x-7 gap-x-3">
                    {numberFormats.map((item, index) => (
                      <Radio
                        key={index}
                        label={item}
                        labelProps={{
                          className:
                            "text-base xl:text-lg font-medium text-info-400",
                        }}
                        color="themeColor"
                        name="number"
                        checked={values.number_format === item}
                        onChange={(event) => {
                          setFieldValue("number_format", item);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-theme xl:p-8 lg:p-6 p-4 mb-[14px]">
                <p className="pb-6 text-lg font-semibold text-info-500 lg:text-xl">
                  {t("Currency")}
                </p>
                <div className="pb-1 mb-4 overflow-x-auto xl:mb-8 lg:mb-6 xl:pb-0">
                  <div className="flex flex-wrap items-center gap-3 xl:gap-5">
                    {currencyList.map((item, index) => (
                      <button
                        type="button"
                        className={`w-[50px] h-[50px] rounded-sm font-medium text-info-200 bg-secondary-50 text-xl transition-all ease-in-out ${item._id === values.currency._id
                          ? "bg-white text-primary-400 !border-primary-400"
                          : " hover:bg-white hover:border-primary-400 hover:text-primary-400"
                          } duration-200 border border-secondary-500`}
                        key={index}
                        onClick={() => setFieldValue("currency", item)}
                      >
                        {item?.symbol}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="">
                  <div className="block text-base xl:text-base text-secondary-500 xl:pb-5 lg:pb-4 pb-3.5 font-medium">
                    {t("Currency Type")}
                  </div>
                  <div className="flex items-center gap-8 2xl:gap-16 lg:gap-x-7 gap-x-3">
                    <Radio
                      label={`${values.currency.symbol} 1,235.79 `}
                      labelProps={{
                        className: "text-lg font-medium text-info-400",
                      }}
                      color="themeColor"
                      name="is_symbol_before"
                      checked={values.is_symbol_before === true}
                      onChange={(event) => {
                        setFieldValue("is_symbol_before", true);
                      }}
                    />
                    <Radio
                      label={`1,235.79 ${values.currency.symbol}`}
                      labelProps={{
                        className: "text-lg  font-medium text-info-400",
                      }}
                      color="themeColor"
                      name="is_symbol_before"
                      checked={values.is_symbol_before === false}
                      onChange={(event) => {
                        setFieldValue("is_symbol_before", false);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-theme lg:py-5 lg:px-6 p-4 mb-[14px]">
                <p className="pb-6 text-lg font-semibold text-info-500 lg:text-xl">
                  {t("Customer Loyalty")}
                </p>
                <div className="xl:mb-6 lg:mb-3 mb-2 max-w-[559px] flex gap-16">
                  <Switch
                    name="loyalty_point_enable"
                    label={t("Loyalty Points")}
                    labelProps={{
                      className: "text-secondary-500 font-medium order-1 ml-0",
                    }}
                    ripple={false}
                    className="w-full h-full bg-secondary-200/40 checked:bg-primary-400"
                    containerProps={{
                      className: "w-11 h-6 order-2 ml-[64px]",
                    }}
                    circleProps={{
                      className: "before:hidden left-0.5 border-none",
                    }}
                    checked={values.loyalty_point_enable}
                    onChange={(event) =>
                      setFieldValue(
                        "loyalty_point_enable",
                        event.target.checked
                      )
                    }
                  />
                </div>

                <div className="xl:mb-6 mb-5 max-w-[560px]">
                  <div className="block text-base xl:text-base text-secondary-500 xl:pb-5 lg:pb-4 pb-3.5 font-medium">
                    {t("Redeemable Point Minimum")}
                  </div>
                  <div className="flex flex-wrap items-center 2xl:gap-x-[70px] lg:gap-x-7 gap-x-3">
                    {(minRedeemablePoints || []).map((item, index) => (
                      <Radio
                        name="min_redeemable_points"
                        color="themeColor"
                        className="capitalize"
                        checked={values.min_redeemable_points === item}
                        label={item}
                        value={item}  // Convert item to number
                        labelProps={{
                          className:
                            "text-base xl:text-lg font-medium text-info-400 pl-1",
                        }}
                        key={index}
                        onChange={(e) => setFieldValue("min_redeemable_points", Number(e.target.value))}
                      />
                    ))}
                  </div>
                </div>

                <div className="mb-4 xl:mb-6 lg:mb-5">
                  <label className="block text-base xl:text-base text-secondary-500 xl:pb-5 lg:pb-4 pb-3.5 font-medium">
                    {t("Points Redeemable Delay")}
                  </label>
                  <div className="flex flex-wrap items-center 2xl:gap-x-[70px] lg:gap-x-7 gap-x-3 capitalize">
                    {pointsRedeemableDelay.map((item, index) => (
                      <Radio
                        name="points_redeemable_delay"
                        color="themeColor"
                        checked={values.points_redeemable_delay === item.value}
                        label={item.label}
                        value={item.value}
                        labelProps={{
                          className:
                            "text-base xl:text-lg font-medium text-info-400 pl-1",
                        }}
                        key={index}
                        onChange={handleChange}
                      />
                    ))}
                  </div>
                </div>

                <div className="xl:mb-6 lg:mb-5 mb-4 max-w-[559px]">
                  <div className="block text-base xl:text-base text-secondary-500 lg:pb-4 pb-3.5 font-medium">
                    {t("Value of a Points")}
                  </div>
                  <ReactSelect
                    options={valueOfLoyaltyPoints.map((num) => ({
                      value: num,
                      label: num,
                    }))}
                    name="value_of_loyalty_points"
                    value={values.value_of_loyalty_points}
                    components={{
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                    onChange={(data) =>
                      setFieldValue("value_of_loyalty_points", data)
                    }
                    menuPlacement="top"
                  />
                </div>
                <div className="flex items-center gap-3 mb-4 xl:gap-7 lg:gap-5">
                  {/* <Button
                    type="submit"
                    className="text-base font-semibold capitalize bg-white border rounded-theme text-primary-400 border-primary-400"
                  >
                    cancel
                  </Button> */}
                  <Button
                    type="submit"
                    className="flex items-center justify-center text-base font-medium capitalize border border-primary-400 bg-primary-400 min-w-52 rounded-theme"
                    loading={isUpdateLoading}
                    disabled={isUpdateLoading || !(isValid && dirty)}
                  >
                    {t("Update All Device")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default AllDeviceSettings;
