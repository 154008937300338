import React, { useLayoutEffect, useState } from "react";
import Layout from "../../layout/index";
import AllOrder from "./AllOrder";
import FilterSettings from "./FilterSettings";
import HoldDetails from "./HoldDetails";
import { useDispatch, useSelector } from "react-redux";
import { getOrderListRequest } from "../../store/order/action";
import {
  filterOptions,
  formatCurrencyWithNumberValue,
  formatDateValue,
  getUserOrgLocationSetting,
  orderStatusOptions,
  useDebounce,
} from "../../utils/utils";
import DataTable from "../../components/common/DataTable";
import moment from "moment";
import ReactSelect from "../../components/common/ReactSelect";
import Input from "../../components/common/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faClose,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  Chip,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
} from "@material-tailwind/react";
import { ExportIcon, ShortingIcon } from "../../components/svgs";
import Modal from "../../components/common/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { orderStatus } from "../../utils/enums";

const Order = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const { isLoading, orderListData, totalPages } = useSelector((store) => ({
    isLoading: store?.order?.orderList?.loading,
    orderListData: store?.order?.orderList?.data,
    totalPages: store?.order?.orderList?.totalPages,
  }));
  const orderStatusOptions = [
    { label: `${t("All")}`, value: null },
    { label: `${t("Completed")}`, value: "completed" },
    { label: `${t("Hold")}`, value: "hold" },
    { label: `${t("Returned")}`, value: "return" },
  ];
  const filterOptions = [
    { label: `${t("This Year")}`, value: "this_year" },
    { label: `${t("Last Week")}`, value: "last_week" },
    { label: `${t("Last Month")}`, value: "last_month" },
    { label: `${t("Last 6 Months")}`, value: "last_6_months" },
    { label: `${t("Last Year")}`, value: "last_year" },
  ];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(orderStatusOptions[0]);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false); //open delete confirmation dialog
  const [selectedDateType, setSelectedDateType] = useState("today");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedResource, setSelectedResource] = useState([]);
  const [openViewOrder, setViewOrder] = useState(false);

  const getOrgDetail = getUserOrgLocationSetting();

  const searchValue = useDebounce(search); //for search

  console.log(selectedResource, "selectedResource............");

  // Define columns for table
  const columns = () => [
    {
      Header: t("Order"),
      key: "orderNo",
    },
    {
      Header: t("Customer Name"),
      key: "",
      render: (row) => (
        <p className="font-medium capitalize">{row?.customerName}</p>
      ),
    },
    {
      Header: t("Total Price"),
      key: "",
      render: (row) => (
        <p className="font-medium text-secondary-400">
          {formatCurrencyWithNumberValue(row?.totalPrice)}
        </p>
      ),
    },
    {
      Header: t("Status"),
      key: "",
      render: (row) => (
        <div>
          <Chip
            color={
              row?.status === orderStatus.COMPLETED
                ? "success"
                : row.status === orderStatus.RETURN
                  ? "red"
                  : "orange"
            }
            variant="ghost"
            value={row?.status === "return" ? t("returned") : t(row?.status)}
            className="inline-flex capitalize rounded-full"
          />
        </div>
      ),
    },
    {
      Header: t("Sold By"),
      key: "",
      render: (row) => (
        <div>
          <p className="font-medium capitalize text-secondary-400">
            {row?.soldBy}
          </p>
        </div>
      ),
    },
    {
      Header: t("Date"),
      key: "",
      render: (row) => (
        <p className="font-medium capitalize text-secondary-400">
          {moment(row?.date).format(
            `${getOrgDetail?.date_format?.date_format} hh.mm A`
          )}
        </p>
      ),
    },
    {
      Header: t("Resource"),
      key: "",
      render: (row) => (
        <div className="flex items-center gap-x-3">
          <div
            className={`w-1.5 h-1.5 rounded-full ${row.resource === "store"
                ? " bg-danger"
                : row?.resource === "e-store" || "online store"
                  ? "bg-success"
                  : ""
              }`}
          ></div>
          <p className="text-sm font-semibold capitalize 2xl:text-base">
            {t(row.resource)}
          </p>
        </div>
      ),
    },
    // {
    //   Header: "",
    //   key: "",
    //   render: (row) => (
    //     <div>
    //       {selectedStatus?.value?.toLowerCase() === "hold" && (
    //         <IconButton
    //           size="sm"
    //           type="button"
    //           variant="text"
    //           className="text-red-500"
    //           onClick={() => {
    //             setOpenDeleteConfirmation(true);
    //           }}
    //         >
    //           <FontAwesomeIcon icon={faTrashAlt} fontSize={18} />
    //         </IconButton>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const handleSearch = (event) => {
    setSearch(event.target.value);
    if (page > 1) setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page.selected + 1);
  };

  const handlePerPageChange = (limit) => {
    setLimit(limit);
  };

  // pass filter data in API
  const applyFilter = () => {
    setPage(1);
    dispatch(
      getOrderListRequest({
        page,
        limit,
        search: searchValue,
        status: selectedStatus?.value || "",
        dateType: selectedDateType?.value || selectedDateType,
        resource: selectedResource,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
    setOpenPopover(false);
  };

  // dispatch get order list request
  useLayoutEffect(() => {
    dispatch(
      getOrderListRequest({
        page,
        limit,
        search: searchValue,
        status: selectedStatus?.value || "",
        dateType: selectedDateType?.value || selectedDateType,
        resource: selectedResource,
        start_date: selectedDateType === "custom" ? startDate : "",
        end_date: selectedDateType === "custom" ? endDate : "",
      })
    );
  }, [
    dispatch,
    page,
    limit,
    searchValue,
    selectedStatus,
    // selectedDateType,
    // selectedResource,
  ]);

  return (
    <div>
      <Layout>
        <div className=" xl:h-[calc(100vh-119px)] h-[calc(100vh-100px)]">
          <div className="flex items-center pb-4 ">
            <div className="flex items-center justify-between w-full ">
              {/* Search tab */}
              <div className="relative flex-1 pr-5 border-r border-secondary-200">
                <Input
                  type={"search"}
                  id={"search-input"}
                  className={
                    "bg-secondary-50 placeholder:font-medium placeholder:text-secondary-500 placeholder:text-sm"
                  }
                  iconStart={
                    <FontAwesomeIcon
                      icon={faSearch}
                      fontSize={16}
                      className="text-secondary-500"
                    />
                  }
                  placeholder={t("Search For Leads")}
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              {/* Select status */}
              <div className="flex gap-2.5 items-center py-2.5 px-5">
                <div>
                  <ReactSelect
                    labelProps={"!text-xs font-semibold capitalize"}
                    options={orderStatusOptions}
                    value={selectedStatus}
                    onChange={(data) => {
                      setPage(1);
                      setSelectedStatus(data);
                    }}
                    placeholder={language === "fr" ? "Tous" : "All"}
                  />
                </div>
              </div>
            </div>
            {/* filter section */}
            <div className="flex items-center gap-3">
              <div className="min-w-[200px]">
                <Popover
                  placement="bottom-end"
                  open={openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler>
                    <Button className="flex items-center gap-3 text-sm font-semibold capitalize bg-white text-secondary-400 !shadow-md py-[12.5px]">
                      <ShortingIcon />
                      {t("Sort by date")}
                      <FontAwesomeIcon
                        icon={openPopover ? faAngleUp : faAngleDown}
                      />
                    </Button>
                  </PopoverHandler>
                  <PopoverContent className="z-10 lg:w-[900px] w-[calc(100%-16px)] space-y-4">
                    <div className="flex items-center justify-between">
                      <p className="text-lg font-semibold capitalize text-info-500">
                        {t("Filter Options")}
                      </p>
                      <FontAwesomeIcon
                        onClick={() => setOpenPopover(false)}
                        icon={faClose}
                        className="w-5 h-5 text-info-500"
                      />
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("Date Range")}
                      </p>
                      <div className="flex items-center gap-2 pt-4">
                        {[
                          "custom",
                          "today",
                          "yesterday",
                          "this_week",
                          "this_month",
                        ].map((type) => (
                          <p
                            key={type}
                            onClick={() => setSelectedDateType(type)}
                            className={`border ${selectedDateType === type
                                ? "border-primary-400 bg-primary-150 text-primary-400"
                                : "border-secondary-200 text-secondary-400"
                              } flex items-center justify-center xl:text-base text-sm_18 font-medium  rounded-3xl xl:px-[30px] lg:px-5 px-3.5 xl:py-3 py-2 cursor-pointer transition-all duration-300 text-nowrap capitalize`}
                          >
                            {t(type.split("_").join(" "))}
                          </p>
                        ))}
                        <ReactSelect
                          labelProps={"!text-xs font-semibold capitalize"}
                          options={filterOptions}
                          value={selectedDateType}
                          onChange={(data) => setSelectedDateType(data)}
                          placeholder={language === "fr" ? "plus" : "More"}
                        />
                      </div>
                      {selectedDateType === "custom" && (
                        <div className="flex items-center gap-5 pt-6">
                          <div>
                            <Input
                              label={t("Start Date")}
                              type={"date"}
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              max={new Date().toISOString().split("T")[0]}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                          <div className="">
                            <FontAwesomeIcon
                              icon={faArrowRightLong}
                              className="w-5 h-5 mt-[32px]"
                            />
                          </div>
                          <div>
                            <Input
                              label={t("End Date")}
                              type={"date"}
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              max={new Date().toISOString().split("T")[0]}
                              className={
                                "bg-secondary-50 xl:max-w-[248px] lg:max-w-[190px] max-w-[170px] placeholder:font-medium placeholder:text-secondary-500 text-base px-2"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-5 border border-secondary-200 rounded-theme">
                      <p className="text-lg font-medium capitalize text-secondary-400">
                        {t("source")}
                      </p>
                      <div className="flex items-center gap-3 pt-2.5">
                        {["online-store", "store"].map((resource) => (
                          <Checkbox
                            key={resource}
                            label={t(resource)}
                            name="resource"
                            // value={resource}
                            onChange={(e) => {
                              // const arr = [...formik.values.categories_tag];
                              const tempArr = [...selectedResource];

                              if (e.target.checked) {
                                // arr.push(item._id);
                                tempArr.push(resource);
                              } else {
                                const findValue = tempArr.findIndex(
                                  (value) => value === resource
                                );

                                if (findValue > -1) {
                                  tempArr.splice(findValue, 1);
                                }
                              }
                              setSelectedResource(tempArr);
                            }}
                            checked={selectedResource.includes(resource)}
                            className=" w-5 h-5 border border-[#0F1A2A]"
                            labelProps={{
                              className: `text-sm font-semibold capitalize ${resource === "online-store"
                                  ? "text-success"
                                  : resource === "store"
                                    ? "text-danger"
                                    : ""
                                } pl-1`,
                            }}
                          // containerProps={{ className: "p-0" }}
                          />
                        ))}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      fullWidth
                      onClick={applyFilter}
                      className="bg-primary-400 capitalize font-medium text-base w-[143px] h-[46px]"
                    >
                      {t("Filter")}
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
              {/* <Button
                type="button"
                className="flex items-center w-full py-3 text-sm font-medium capitalize border border-primary-400 bg-primary-400/80 text-primary-400 gap-x-3 rounded-theme"
              >
                <ExportIcon />
                {t("Export")}
              </Button> */}
            </div>
          </div>
          {/* Order list table section */}
          <div className="bg-white w-full xl:p-5 lg:p-4 p-3.5 rounded-theme">
            <DataTable
              columns={columns()}
              data={orderListData}
              search={""}
              openPopover={openPopover}
              maxHeight="max-h-[calc(100vh-195px)]"
              loading={isLoading}
              page={page}
              totalPages={totalPages}
              onSelect={(row) => navigate(`/order/view-order/${row?._id}`)}
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
              message={t("No Data Found")}
            />
          </div>
        </div>

        {/* Dialog for delete */}
        <Modal
          open={openDeleteConfirmation}
          handler={() => {
            setOpenDeleteConfirmation(false);
            // setSelectedOrderId(null);
          }}
        >
          <div className="flex flex-col ">
            <p className="pb-3 text-lg font-semibold text-info-400">
              {t("Delete")}
            </p>
            <p className="text-base font-medium text-secondary-400 pb-7">
              {t("Are you sure to delete this product ?")}
            </p>
            <div className="flex items-center justify-end gap-x-4">
              <Button
                type="button"
                size="sm"
                className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
                onClick={() => {
                  setOpenDeleteConfirmation(false);
                  // setSelectedOrderId(null);
                }}
              >
                {t("No, Cancel")}
              </Button>
              <Button
                type="button"
                size="sm"
                className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              // loading={deleteLoading}
              // disabled={deleteLoading}
              // onClick={() =>
              //   dispatch(
              //     deleteProductRequest({
              //       productId: selectedProductId,
              //       setModalClose: setOpenDeleteConfirmation,
              //     })
              //   )
              // }
              >
                {t("Yes, delete order")}
              </Button>
            </div>
          </div>
        </Modal>
      </Layout>
    </div>
  );
};

export default Order;
