import React from "react";
import DownArrowBlackIcon from "../../assets/icon/down-arrow-black.svg";
import IntegrationLayout from "../../layout/IntegrationLayout";
import { Button } from "@material-tailwind/react";
import { t } from "i18next";
import { Field, Form, Formik } from "formik";
import { updatePaymentTypesRequest } from "../../store/settings/integration/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { profileAction } from "../../store/settings/settings.fetch";

const IntegrationPayment = () => {

  const dispatch = useDispatch();

  const { isLoading, paymentTypeData, defaultOrgLocationId, isProfileLoading, payment_type } = useSelector((store) => ({
    isLoading: store?.integration?.paymentTypeData?.loading,
    paymentTypeData: store?.integration?.paymentTypeData?.data,
    defaultOrgLocationId: store?.setting?.defaultOrgLocation?._id,
    isProfileLoading: store?.setting?.isProfileLoading,
    payment_type: store?.setting?.organization_setting?.payment_type,

  }));


  // const { payment_type } = useSelector((store) => ({
  //   payment_type: store?.setting?.organization_setting?.payment_type,
  // }));



  console.log(isProfileLoading, "isProfileLoading....")
  return (
    <IntegrationLayout>
      <div className="grow overflow-y-auto xl:h-[calc(100vh-132px)] h-[calc(100vh-106px)]">
        <Formik
          enableReinitialize
          initialValues={{
            // cash: false,
            store_credit: payment_type?.store_credit ? true : false,
            gift_card: payment_type?.gift_card ? true : false,
          }}
          onSubmit={async (values) => {
            console.log("Form Submitted:", values);
            // Check if all values are false to disable the button

            await dispatch(updatePaymentTypesRequest({ defaultOrgLocationId, values }))

            dispatch(profileAction());
            // Your form submit logic here (e.g., API call)
          }}
        >
          {({ values, setFieldValue, dirty }) => {
            // const isButtonDisabled = !values.store_credit && !values.gift_card

            return (
              <>
                {isProfileLoading && (
                  <div className="absolute z-20 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
                    <Loader />
                  </div>
                )}
                <Form>
                  <div className="px-4 py-5 mb-5 bg-white rounded-theme px">
                    <div className="mb-3 text-base font-semibold text-info-500 lg:text-xl lg:mb-6">
                      {t("Basic Payment Types")}
                    </div>
                    {/* <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
                <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                  {t("Cash")}
                </div>
                <div>
                  <label className="relative inline-block h-5 w-11">
                    <Field type="checkbox" name="cash" className="w-0 h-0 opacity-0 peer" />
                    <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
                  </label>
                </div>
              </div> */}

                    {/* Store Credit Checkbox */}
                    <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
                      <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                        {t("Store Credit")}
                      </div>
                      <div>
                        <label className="relative inline-block h-5 w-11">
                          <Field
                            type="checkbox"
                            name="store_credit"
                            className="w-0 h-0 opacity-0 peer"
                            checked={values.store_credit}
                            onChange={() => setFieldValue('store_credit', !values.store_credit)} // Toggle value on change
                          />
                          <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
                        </label>
                      </div>
                    </div>


                    {/* Gift Card Checkbox */}
                    <div className="flex items-center justify-between">
                      <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
                        {t("Gift Card")}
                      </div>
                      <div>
                        <label className="relative inline-block h-5 w-11">
                          <Field
                            type="checkbox"
                            name="gift_card"
                            checked={values.gift_card}
                            className="w-0 h-0 opacity-0 peer"
                            onChange={() => setFieldValue('gift_card', !values.gift_card)} // Toggle value on change
                          />
                          <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-primary-150 peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="bg-white rounded-theme py-5 px-4 lg:mb-[30px] mb-4">
          <div className="mb-3 text-base font-semibold text-info-500 lg:text-lg lg:mb-6">
            Advanced Payment Types
          </div>
          <div className="mb-[30px]">
            <div className="mb-3 font-medium text-secondary-500 xl:text-lg lg:text-base text-sm_18">
              Payment Type
            </div>
            <div className="bg-secondary-50 lg:rounded-theme rounded-base flex items-center justify-between lg:py-[18px] lg:px-5 py-3 px-3">
              <div className="font-medium text-info-500 xl:text-lg lg:text-base text-sm_18">
                SideCar
              </div>
              <div>
                <img
                  src={DownArrowBlackIcon}
                  alt="down arrow"
                  className="w-5 h-5 lg:w-6 lg:h-6"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
              Credit card
            </div>
            <div>
              <label className="relative inline-block h-5 w-11">
                <input type="checkbox" className="w-0 h-0 opacity-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
              Debit card
            </div>
            <div>
              <label className="relative inline-block h-5 w-11">
                <input type="checkbox" className="w-0 h-0 opacity-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
              Gift card
            </div>
            <div>
              <label className="relative inline-block h-5 w-11">
                <input type="checkbox" className="w-0 h-0 opacity-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
              integrate with card smith
            </div>
            <div>
              <label className="relative inline-block h-5 w-11">
                <input type="checkbox" className="w-0 h-0 opacity-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between border-b border-secondary-200/30 lg:mb-4 lg:pb-4 mb-2.5 pb-2.5">
            <div className="font-medium text-secondary-400 xl:text-lg lg:text-base text-sm_18">
              Allow clerks to reload this
            </div>
            <div>
              <label className="relative inline-block h-5 w-11">
                <input type="checkbox" className="w-0 h-0 opacity-0 peer" />
                <span className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-secondary-200/40 rounded-full transition duration-400 peer-checked:bg-[#FDC8B8] peer-checked:before:translate-x-6 before:absolute before:content-[''] before:h-[23px] before:w-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-white peer-checked:before:bg-primary-400 before:rounded-full  before:shadow-[1px_0px_8.2px_0px_#0000002E] before:transition before:duration-400"></span>
              </label>
            </div>
          </div>
          <div className="lg:mb-[30px] mb-4">
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Branded Card Name
            </label>
            <input
              type="text"
              className="w-full p-3 font-medium outline-none bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 text-info-500 xl:text-lg lg:text-base text-sm_18"
              value="HDFC Bank"
              placeholder="Enter Card Name"
            />
          </div>
          <div className="lg:mb-[30px] mb-4">
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Merchant Id
            </label>
            <input
              type="text"
              className="w-full p-3 font-medium outline-none bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 text-info-500 xl:text-lg lg:text-base text-sm_18"
              value="42563523"
              placeholder="Enter Merchant Id"
            />
          </div>
          <div>
            <label className="text-secondary-500 xl:text-lg lg:text-base text-sm_18 font-medium lg:mb-3 mb-1.5 block">
              Branded Card Name
            </label>
            <input
              type="text"
              className="w-full p-3 font-medium outline-none bg-secondary-50 lg:rounded-theme rounded-base xl:p-5 text-info-500 xl:text-lg lg:text-base text-sm_18"
              value="69584582"
              placeholder="Enter Terminal Id"
            />
          </div>
        </div> */}
                  <Button
                    loading={isLoading}
                    disabled={!dirty} // Disable button if all are false
                    type="submit" // Use Formik's submit handler
                    className="bg-primary-400 xl:max-w-[230px] max-w-[180px] flex items-center justify-center w-full text-center border border-primary-400 lg:text-base text-sm_20 font-medium h-[46px] text-white rounded-theme capitalize">
                    {t("Save")}
                  </Button>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
    </IntegrationLayout >
  );
};

export default IntegrationPayment;
