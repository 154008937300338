import { toast } from "react-toastify";
import AxiosRequest from "../../../AxiosRequest";
import { integrationState } from "./state";

/* -------------------------------------------------------------------------- */
/*                           get all device request                           */
/* -------------------------------------------------------------------------- */
export const getSystemAndLogRequest = () => async (dispatch) => {
    try {
        dispatch(integrationState({ key: "getSystemLogData.loading", value: true }));
        const response = await AxiosRequest.get(`/user/log/system-logs`);
        const data = await response.data.data;

        console.log(data, "data....................")
        if (data) {
            dispatch(integrationState({ key: "getSystemLogData.data", value: data }));
            dispatch(integrationState({ key: "getSystemLogData.loading", value: false }));
        }
        return response;
    } catch (error) {
        toast.error(error);
        dispatch(integrationState({ key: "getSystemLogData.loading", value: false }));
        dispatch(integrationState({ key: "getSystemLogData.error", value: error }));
    }
};


/* -------------------------------------------------------------------------- */
/*                           update payment type request                           */
/* -------------------------------------------------------------------------- */
export const updatePaymentTypesRequest = ({ defaultOrgLocationId, values }) => async (dispatch) => {
    try {
        dispatch(integrationState({ key: "paymentTypeData.loading", value: true }));
        const response = await AxiosRequest.put(`/user/update-payment-setting?organization_location_id=${defaultOrgLocationId}`, values);
        const data = await response.data.data;

        console.log(data, "data....................")
        if (data) {
            dispatch(integrationState({ key: "paymentTypeData.data", value: data }));
            dispatch(integrationState({ key: "paymentTypeData.loading", value: false }));

            toast.success(response.data.message);
        }
        return response;
    } catch (error) {
        toast.error(error);
        dispatch(integrationState({ key: "paymentTypeData.loading", value: false }));
        dispatch(integrationState({ key: "paymentTypeData.error", value: error }));
    }
};