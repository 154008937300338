import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    getSystemLogData: {
        loading: false,
        data: [],
        error: null,
    },
    paymentTypeData: {
        loading: false,
        data: [],
        error: null,
    }
};

export const integrationSlice = createSlice({
    name: "integration",
    initialState: initialState,
    reducers: {
        integrationState: (state, { payload }) => {
            if (Array.isArray(payload)) {
                for (const obj of payload) {
                    _.set(state, obj.key, obj.value);
                }
            } else {
                _.set(state, payload.key, payload.value);
            }
        },
    },
});

export const { integrationState } = integrationSlice.actions;

export default integrationSlice.reducer;
