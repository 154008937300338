import { toast } from "react-toastify";
import AxiosRequest from "../../AxiosRequest";
import { settingState } from "./settings.slice";

/* -------------------------------------------------------------------------- */
/*               get predefine value and logged in user details               */
/* -------------------------------------------------------------------------- */
export const profileAction = () => async (dispatch) => {
  try {
    dispatch(
      settingState({
        key: "isProfileLoading",
        value: true,
      })
    );
    const response = await AxiosRequest.get("/auth/profile");
    const { data: profileData } = response.data;
    if (profileData) {
      dispatch(
        settingState({
          key: "isProfileLoading",
          value: false,
        })
      );
      dispatch(
        settingState({
          key: "currencyList",
          value: profileData?.currencyList || [],
        })
      );
      dispatch(
        settingState({
          key: "dateFormatList",
          value: profileData?.dateFormatList || [],
        })
      );
      dispatch(
        settingState({
          key: "languageList",
          value: profileData?.languageList || [],
        })
      );
      dispatch(
        settingState({
          key: "numberFormats",
          value: profileData?.numberFormats || [],
        })
      );
      dispatch(
        settingState({
          key: "minRedeemablePoints",
          value: profileData?.minRedeemablePoints || [],
        })
      );
      dispatch(
        settingState({
          key: "pointsRedeemableDelay",
          value:
            profileData?.pointsRedeemableDelay?.map((item) => ({
              label: item,
              value: item,
            })) || [],
        })
      );
      dispatch(
        settingState({
          key: "roleList",
          value: profileData?.roleList || [],
        })
      );
      dispatch(
        settingState({
          key: "valueOfLoyaltyPoints",
          value: profileData?.valueOfLoyaltyPoints || [],
        })
      );
      dispatch(
        settingState({
          key: "defaultOrgLocation",
          value: profileData?.defaultOrgLocation || null,
        })
      );
      dispatch(
        settingState({
          key: "user_device_setting",
          value: profileData?.user_device_setting || null,
        })
      );
      dispatch(
        settingState({
          key: "organization",
          value: profileData?.organization || null,
        })
      );
      dispatch(
        settingState({
          key: "organization_setting",
          value: profileData?.organization_setting || null,
        })
      );
    }

    return response;
  } catch (error) {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refresh_token");
    // navigate("/");
    toast.error(error);
    // dispatch(authSetState({ key: "loading", value: false }));
  }
};

/* -------------------------------------------------------------------------- */
/*                           get all store locations                          */
/* -------------------------------------------------------------------------- */
export const getLocationListRequest = () => async (dispatch) => {
  try {
    dispatch(settingState({ key: "locationList.loading", value: true }));
    const response = await AxiosRequest.get("/admin/orgsLocation/locations");
    const data = await response.data.data;
    if (data) {
      dispatch(settingState({ key: "locationList.data", value: data }));
      dispatch(settingState({ key: "locationList.loading", value: false }));
    }
    return response;
  } catch (error) {
    toast.error(error);
    dispatch(settingState({ key: "locationList.loading", value: false }));
    dispatch(settingState({ key: "locationList.error", value: error }));
  }
};
