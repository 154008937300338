import {
  faCirclePause,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faClose,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Radio, Tooltip } from "@material-tailwind/react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrderContext } from "../helpers/context/OrderContext";
import { formatCurrencyWithNumberValue } from "../utils/utils";
import Input from "./common/Input";
import Modal from "./common/Modal";
import CustomerList from "./customer/CustomerList";
import AddGiftCard from "./order/AddGiftCard";
import HoldOrder from "./order/HoldOrder";
import ManageOrder from "./order/ManageOrder";
import EmptyOrder from "./svgs/EmptyOrder";
import { AddGiftCardIcon } from "./svgs";
import Loader from "./common/Loader";
import { orderState } from "../store/order/state";
import { Navigate, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { discountType } from "../utils/enums";
import { addToLastTransactionRequest, getLastTransactionRequest } from "../store/order/actions";

const CustomerOrderWrapper = ({ orderId, selectedCategory }) => {
  const { t } = useTranslation();
  const { formik } = useContext(OrderContext);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const tax_details = useSelector(
    (store) => store?.setting?.defaultOrgLocation?.tax_details
  );
  const isLoading = useSelector((store) => store?.order?.addToCart?.loading);

  const {
    customerData,
    orderItems,
    // productsData,
    lastTransactionLoading,
    lastTransactionDetails,
    lastTransactionItems,
    customer,
  } = useSelector((store) => ({
    customerData: store?.order?.orderDetail?.data?.customer,
    orderItems: store?.order?.orderDetail?.data?.orderItems,
    // productsData: store?.products?.products?.data, 
    lastTransactionLoading: store?.order?.lastTransactionDetail?.loading,
    lastTransactionDetails: store?.order?.lastTransactionDetail?.data,
    lastTransactionItems: store?.order?.lastTransactionDetail?.data?.lastSelectedProducts,
    customer: store?.order?.cartItems?.data?.customer,
  }));

  console.log(lastTransactionItems, "lastTransactionItems......................");

  const navigate = useNavigate();

  // Calculate the total tax percentage
  const totalTaxPercentage = (tax_details || [])?.reduce(
    (acc, t) => acc + t.tax_percentage,
    0
  );

  const [openSearchCustomer, setOpenSearchCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openGiftCardModal, setOpenGiftCardModal] = useState(false);
  const [openHoldOrderModal, setOpenHoldOrderModal] = useState(false);
  const [holdOrderData, setHoldOrderData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedEditProduct, setSelectedEditProduct] = useState();
  const [editClickProduct, setEditClickProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [openEditModal, setOpenEditModal] = useState();

  console.log(selectedCustomer, "selectedCustomer....................");

  // on change into cart anything call api for store last transaction
  // Create a ref to store the timeout ID
  let debounceTimer = useRef(null);
  useEffect(() => {
    // Clear the previous timeout and set a new one
    clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      const createCartData = {
        customer_id: selectedCustomer ? selectedCustomer._id : "",
        orderItems: formik.values.orderItems.map((item) => {
          if (item.type === "inventory") {
            const { product_name, variant_name, total_qty, ...element } = item;

            return {
              ...element,
              discount_percentage:
                element?.discount_type === discountType.PERCENTAGE
                  ? Number(element?.discount_percentage)
                  : 0,
            };
          } else {
            return {
              type: item?.type,
              gift_card_code: item.gift_card_code,
              gift_card_amount: Number(item.gift_card_amount),
            };
          }
        }),
      };

      // Dispatch the API call with the body
      dispatch(addToLastTransactionRequest({ body: createCartData }));
    }, 300); // Set the debounce delay to 500ms (adjustable)


    // Cleanup the timeout on component unmount or before the next effect
    return () => clearTimeout(debounceTimer.current);
  }, [formik.values.orderItems, selectedCustomer]);  // Trigger the effect whenever orderItems or selectedCustomer changes

  // get last transaction detail and it have then add it directly into order 
  useEffect(() => {
    dispatch(getLastTransactionRequest())
  }, [])

  // on everytime come to this page add last transaction details into Cart 
  useEffect(() => {
    if (lastTransactionDetails) {
      setSelectedCustomer(lastTransactionDetails?.customer_id);
      formik.setFieldValue("order.customer_id", lastTransactionDetails?.customer_id);

      if (lastTransactionItems?.length > 0) {
        // Map over lastTransactionItems and process them as needed
        const updatedOrderItems = lastTransactionItems.map((lastTransactionItem) => {
          if (lastTransactionItem.type === "inventory") {
            const variantPossibilities = lastTransactionItem?.inventory_id?.variants_possibilities;

            const matchingVariant = variantPossibilities?.find(
              (variant) => variant.sku === lastTransactionItem.sku
            );

            const variantName = matchingVariant ? matchingVariant.name : "";
            const total_qty = matchingVariant ? matchingVariant.stock : lastTransactionItem?.inventory_id?.quantities;

            return {
              type: "inventory",
              inventory_id: lastTransactionItem?.inventory_id?._id,
              product_name: lastTransactionItem?.inventory_id?.product_name || "Unknown Product",
              sku: lastTransactionItem?.sku,
              qty: lastTransactionItem?.qty,
              total_qty: total_qty,
              selling_price: lastTransactionItem?.selling_price,
              sub_total: lastTransactionItem?.selling_price * lastTransactionItem?.qty,
              discount_type: lastTransactionItem?.discount_type,
              discount_percentage: lastTransactionItem?.discount_type === "amount" ? lastTransactionItem?.discount : lastTransactionItem?.discount_percentage,
              discount: lastTransactionItem?.discount,
              taxes: lastTransactionItem?.taxes || 0,
              grand_total: lastTransactionItem?.grand_total || 0,
              variant_name: variantName.trim(),
            };
          } else {
            return {
              type: lastTransactionItem.type,
              gift_card_code: lastTransactionItem?.gift_card_code || "",
              gift_card_amount: lastTransactionItem?.gift_card_amount || "",
              grand_total: 0,
              discount_percentage: 0,
              discount: 0,
              qty: 1,
            };
          }
        });

        // Directly set the new items into the formik field 'orderItems'
        formik.setFieldValue("orderItems", updatedOrderItems);
      }
    }

  }, [lastTransactionDetails, lastTransactionItems]);


  let productsData;
  useEffect(() => {
    // Retrieve the products data from the query client
    const allProductDatas = queryClient.getQueriesData({
      queryKey: ["productsData"],
    });

    const allProductData = allProductDatas[0];

    // Check if allProductData is defined and has pages
    if (allProductData && allProductData[1]?.pages) {
      productsData = allProductData[1]?.pages?.flatMap(
        (page) => page?.products
      );
    } else {
      console.error("No products data found");
    }
  }, [editClickProduct]);

  // set customer on layout effect call
  useLayoutEffect(() => {
    if (customerData && orderId) {
      setSelectedCustomer(customerData);
      formik.setFieldValue("order.customer_id", customerData?._id);
      setHoldOrderData(orderItems);
    } else if (customerData) {
      setSelectedCustomer(null);
      dispatch(orderState({ key: "orderDetail.data", value: [] }));
    } else if (formik.values.order.customer_id) {
      setSelectedCustomer(formik.values.order.customer_id);
    }
  }, [customerData, orderId, orderItems, formik.values.order.customer_id]);

  useEffect(() => {
    if (customerData === undefined && !formik.values?.order?.customer_id?._id) {
      setSelectedCustomer(null);
      formik.setFieldValue("orderItems", []);
      formik.setFieldValue("order.customer_id", "");
      formik.setFieldValue("order.total_qty", 0);
      formik.setFieldValue("order.sub_total", 0);
      formik.setFieldValue("order.total_discount", 0);
      formik.setFieldValue("order.total_taxes", 0);
      formik.setFieldValue("order.grand_total", 0);
    }
  }, [customerData]);



  const createOrderData = useMemo(() => {
    if (holdOrderData?.length === 0) return [];

    return (holdOrderData || [])?.map((item) => {
      console.log(item, "hold order item............");

      // find total_qty of single product
      let total_qty = 0; // Default to 0
      let variant_name = null;

      if (item?.inventory_id?.variants_possibilities?.length === 0) {
        // No variants, use the quantities field
        total_qty = item?.inventory_id?.quantities || 0;
      } else {
        // Find the matching variant by SKU
        const matchedVariant = item?.inventory_id?.variants_possibilities?.find(
          (variant) => variant?.sku === item?.sku
        );

        // If a matching variant is found, use its stock value
        if (matchedVariant) {
          total_qty = matchedVariant?.stock || 0;
          variant_name = matchedVariant?.name || "";
        }
      }

      if (item.type === "inventory") {
        return {
          type: "inventory",
          inventory_id: item?.inventory_id?._id,
          product_name: item?.inventory_id?.product_name,
          variant_name: variant_name,
          sku: item?.sku,
          qty: item?.qty,
          total_qty: total_qty,
          selling_price: item?.selling_price,
          sub_total: item?.sub_total,
          discount_type: item?.discount_type,
          discount_percentage:
            item?.discount_type === "amount"
              ? item?.discount
              : item?.discount_percentage,
          discount: item?.discount,
          taxes: 0,
          grand_total: 0,
        };
      } else {
        return {
          type: item?.type,
          gift_card_code: item.gift_card_code,
          gift_card_amount: Number(item.sub_total),
          qty: 1,
          discount: 0,
          grand_total: item.grand_total,
          discount_percentage: 0,
        };
      }
    });
  }, [holdOrderData]);

  // Function to handle radio button change
  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
  };

  useEffect(() => {
    if (createOrderData.length > 0) {
      formik.setFieldValue("orderItems", createOrderData);
    }
  }, [createOrderData]);



  // on customer change if already have items on customer cart then add it directly on orderItems
  // useEffect(() => {
  //   if (cartItems?.length > 0 && formik.values.order.customer_id) {
  //     const existingOrderItems = formik.values.orderItems || [];

  //     let total_qty = 0;

  //     // Create a new array to hold merged items
  //     const mergedOrderItems = [...existingOrderItems];

  //     // Iterate over cartItems to merge them into orderItems
  //     cartItems.forEach((cartItem) => {
  //       const existingItemIndex = mergedOrderItems.findIndex(
  //         (orderItem) => orderItem.sku === cartItem.sku
  //       );

  //       if (existingItemIndex === -1) {
  //         // Check if there are variant possibilities (e.g., color, size)
  //         const variantPossibilities =
  //           cartItem?.inventory_id?.variants_possibilities;

  //         // Find the matching variant based on the SKU
  //         const matchingVariant = variantPossibilities?.find(
  //           (variant) => variant.sku === cartItem.sku
  //         );

  //         // Determine the variant name if a matching variant is found
  //         const variantName = matchingVariant ? matchingVariant.name : "";
  //         total_qty = matchingVariant
  //           ? matchingVariant.stock
  //           : cartItem?.inventory_id?.quantities;

  //         mergedOrderItems.push(

  //           cartItem.type === "inventory"
  //             ? {
  //                 type: "inventory",
  //                 inventory_id: cartItem?.inventory_id?._id,
  //                 product_name:
  //                   cartItem?.inventory_id?.product_name || "Unknown Product",
  //                 sku: cartItem?.sku,
  //                 qty: cartItem?.qty,
  //                 total_qty: total_qty,
  //                 selling_price: cartItem?.selling_price,
  //                 sub_total: cartItem?.selling_price * cartItem?.qty,
  //                 discount_type: cartItem?.discount_type,
  //                 discount_percentage:
  //                   cartItem?.discount_type === "amount"
  //                     ? cartItem?.discount
  //                     : cartItem?.discount_percentage,
  //                 discount: cartItem?.discount,
  //                 taxes: cartItem?.taxes || 0, // Assuming taxes are provided
  //                 grand_total: cartItem?.grand_total || 0, // Assuming grand_total is provided
  //                 variant_name: variantName.trim(), // Add the variant name if available
  //               }
  //             : {
  //                 type: cartItem.type,
  //                 gift_card_code: cartItem?.gift_card_code || "",
  //                 gift_card_amount: cartItem?.gift_card_amount || "",
  //                 grand_total: 0,
  //                 discount_percentage: 0,
  //                 discount: 0,
  //                 qty: 1,
  //               }
  //         );
  //       }
  //     });

  //     // Finally, update the formik field with the merged order items
  //     formik.setFieldValue("orderItems", mergedOrderItems);
  //   }
  // }, [cartItems]);

  // gift card close
  const handleGiftCardClose = () => {
    setSelectedIndex(null);
    setOpenGiftCardModal(null);
  };

  // on edit variant have product then Effect to find and set the selected product
  useEffect(() => {
    if (editClickProduct?.inventory_id) {
      const matchingProduct = productsData.find(
        (product) => product._id === editClickProduct.inventory_id
      );

      if (matchingProduct) {
        setSelectedEditProduct(matchingProduct);
        console.log("Found matching product:", matchingProduct);

        // Find and set the variant that matches the editClickProduct SKU
        const matchingVariant = matchingProduct.variants_possibilities?.find(
          (variant) => variant.sku === editClickProduct.sku
        );

        if (matchingVariant) {
          setSelectedVariant(matchingVariant);
        } else if (matchingProduct.variants_possibilities?.length > 0) {
          // Fallback to the first variant if no match is found
          setSelectedVariant(matchingProduct.variants_possibilities[0]);
        }
      } else {
        console.log("No matching product found");
        setSelectedEditProduct(null);
        setSelectedVariant(null); // Reset selected variant if no product matches
      }
    }
  }, [editClickProduct, productsData]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleConfirmReset = () => {
    setSelectedCustomer(null);

    orderId && navigate("/register");
    formik.setFieldValue("orderItems", []);
    formik.setFieldValue("order.customer_id", "");
    formik.setFieldValue("order.total_qty", 0);
    formik.setFieldValue("order.sub_total", 0);
    formik.setFieldValue("order.total_discount", 0);
    formik.setFieldValue("order.total_taxes", 0);
    formik.setFieldValue("order.grand_total", 0);

    // Close modal after reset
    handleCloseModal();
  };

  return (
    <div className=" bg-white rounded-theme flex flex-col overflow-hidden relative xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)]">
      <div className="flex flex-row justify-between">
        <p className="font-medium text-[#363C48] text-[16px] xl:px-4 px-2.5 py-2 whitespace-nowrap">
          {t("Order Summary")}
        </p>
        <p
          className={`px-4 py-2 font-semibold text-[18px] text-danger cursor-pointer whitespace-nowrap flex items-center ${formik.values.orderItems.length === 0
            ? "cursor-not-allowed text-opacity-0 pointer-events-none select-none"
            : "cursor-pointer"
            }`}
          onClick={
            formik.values.orderItems.length === 0 ? null : handleOpenModal
          }
        >
          {t("Reset Order")}
        </p>

        {/*confirmation Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
            <div
              className="bg-white rounded-lg shadow-lg p-4 transform transition-transform duration-300  scale-100
        w-full md:w-2/3 lg:w-2/4 2xl:w-1/3 min-w-[80%] md:min-w-[66.666667%] lg:min-w-[50%] 2xl:min-w-[33.333333%] max-w-[80%] md:max-w-[66.666667%] lg:max-w-[50%] 2xl:max-w-[33.333333%]
        "
            >
              <h2 className="pb-3 text-lg font-semibold text-info-400">
                {t("Reset Order")}
              </h2>
              <p className="text-base font-medium text-secondary-400 pb-7">
                {t("Are you sure you want to reset your order?")}
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="px-5 py-3 border border-[#CBD4E1] text-gray-700 rounded-lg text-sm font-semibold"
                  onClick={handleCloseModal}
                >
                  {t("No, Cancel")}
                </button>
                <button
                  className={`border border-secondary-200 flex items-center justify-center xl:text-sm text- font-semibold ${!selectedCategory
                    ? "bg-primary-400 text-white border-primary-400"
                    : "text-secondary-400"
                    } xl:rounded-theme rounded-base xl:px-5 lg:px-4 px-3.5 xl:py-3 py-2 hover:bg-primary-400 hover:text-white transition-all duration-300 hover:border-primary-400 text-nowrap`}
                  onClick={handleConfirmReset}
                >
                  {t("Yes, Reset Order")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="xl:px-4 px-2.5 pb-4 ">
        <Input
          disabled={orderId}
          type={"text"}
          className={
            "!font-normal placeholder:text-secondary-400 placeholder:text-sm"
          }
          placeholder={t("Select Customer")}
          value={
            selectedCustomer
              ? selectedCustomer?.first_name + " " + selectedCustomer?.last_name
              : ""
          }
          onFocus={(e) => {
            setOpenSearchCustomer(true);
          }}
          iconEnd={
            selectedCustomer ? (
              <FontAwesomeIcon
                icon={faCircleXmark}
                fontSize={16}
                className="cursor-pointer text-secondary-400"
                onClick={() => {
                  // if (!orderId) {
                  navigate("/register");
                  setSelectedCustomer(null);
                  formik.setFieldValue("orderItems", []);
                  formik.setFieldValue("order.customer_id", "");
                  formik.setFieldValue("order.total_qty", 0);
                  formik.setFieldValue("order.sub_total", 0);
                  formik.setFieldValue("order.total_discount", 0);
                  formik.setFieldValue("order.total_taxes", 0);
                  formik.setFieldValue("order.grand_total", 0);
                  // }
                }}
              />
            ) : (
              <FontAwesomeIcon
                disabled={orderId}
                icon={faAngleDown}
                fontSize={16}
                className="text-secondary-400"
              />
            )
          }
        />
      </div>

      <div className="flex-grow xl:px-4 px-2.5 overflow-y-auto">
        {formik?.values?.orderItems?.length > 0 &&
          formik?.values?.orderItems?.map((items, index) => (
            <ManageOrder
              items={items}
              index={index}
              formik={formik}
              totalTaxPercentage={totalTaxPercentage}
              setOpenGiftCardModal={setOpenGiftCardModal}
              setSelectedIndex={setSelectedIndex}
              setEditClickProduct={setEditClickProduct}
              setOpenEditModal={setOpenEditModal}
              lastTransactionLoading={lastTransactionLoading}
              productsData={productsData}
            />
          ))}
        {formik?.values?.orderItems?.length === 0 && !lastTransactionLoading && (
          <div className="flex items-center justify-center h-full">
            <div>
              <EmptyOrder />
              <span className="flex justify-center mt-3 xl:text-base text-sm_18 text-secondary-400">
                {t("Cart Is Empty")}
              </span>
            </div>
          </div>
        )}
        {lastTransactionLoading && (
          <div className="flex items-center justify-center h-full">
            <Loader />
          </div>
        )}
      </div>
      <div className="xl:p-4 px-2.5 py-4">
        <Button
          type="button"
          className="rounded-theme text-secondary-400 flex items-center justify-center gap-x-3.5 border border-secondary-300 bg-transparent font-semibold capitalize xl:text-base text-sm shadow-none"
          fullWidth
          onClick={() => setOpenGiftCardModal(true)}
        >
          <AddGiftCardIcon />
          {t("Add Gift Card")}
        </Button>
      </div>

      <div className="shadow-[0px_-1px_40.2px_0px_rgba(0,0,0,0.05)] xl:p-4 py-4 px-2.5 flex flex-col gap-y-3 rounded-t-theme">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-secondary-400 xl:text-lg">
            {t("Items")}
          </p>
          <p className="text-sm font-medium capitalize text-secondary-400 xl:text-lg">
            {formik?.values?.orderItems?.length} (items)
          </p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-secondary-400 xl:text-lg">
            {t("Sub Total :")}
          </p>
          <p className="text-sm font-medium text-secondary-400 xl:text-lg">
            {formatCurrencyWithNumberValue(
              formik?.values?.order?.sub_total || 0
            )}
          </p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-success xl:text-lg">
            {t("Discount :")}
          </p>
          <p className="text-sm font-semibold text-success xl:text-lg">
            {"-" +
              formatCurrencyWithNumberValue(
                formik?.values?.order?.total_discount || 0
              )}
          </p>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-secondary-400 xl:text-lg">
            {t("Taxes")} ({totalTaxPercentage}%):
          </p>
          <p className="text-sm font-medium text-secondary-400 xl:text-lg">
            {formatCurrencyWithNumberValue(
              formik?.values?.order?.total_taxes || 0
            )}
          </p>
        </div>
        {/* <div className="flex items-center justify-between">
          <p className="text-base font-medium text-info-400 xl:text-lg">
            Loyalty Points:
          </p>
          <p className="text-base font-medium text-info-400 xl:text-lg">
            $0.00
          </p>
        </div> */}
        <hr className="border-dashed border-secondary-500" />
        <div className="flex items-center justify-between py-1">
          <p className="text-base font-semibold text-info-400 xl:text-2xl">
            {t("Total")}
          </p>
          <p className="text-base font-semibold text-info-400 xl:text-2xl">
            {formatCurrencyWithNumberValue(formik?.values?.order?.grand_total)}
          </p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="grid lg:grid-cols-2 items-center xl:gap-5 gap-3.5"
        >
          <Button
            type="button"
            className="rounded-theme text-secondary-400 flex items-center justify-center gap-x-3.5 border border-secondary-400 bg-transparent font-semibold capitalize xl:text-sm text-xs  xl:px-2 2xl:px-2 px-0"
            onClick={() => setOpenHoldOrderModal(true)}
            disabled={
              !(
                formik.isValid &&
                formik.dirty &&
                formik?.values?.orderItems.length > 0
              )
            }
          >
            <FontAwesomeIcon icon={faCirclePause} fontSize={18} />
            {t("Hold Order")}
          </Button>
          {
            (console.log(formik.isValid),
              console.log(formik.dirty),
              console.log(formik?.values?.orderItems.length > 0))
          }
          <Button
            type="submit"
            className="flex items-center justify-center px-2 text-xs font-semibold text-white capitalize border xl:text-sm rounded-theme bg-success border-success gap-x-4"
            loading={isLoading}
            disabled={
              isLoading ||
              !(
                formik.isValid &&
                formik.dirty &&
                formik?.values?.orderItems.length > 0
              )
            }
          >
            {t("Proceed to Pay")}
          </Button>
        </form>
      </div>
      {openSearchCustomer && (
        <CustomerList
          openSearchCustomer={openSearchCustomer}
          setOpenSearchCustomer={setOpenSearchCustomer}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          formik={formik}
        />
      )}
      <Modal
        open={openGiftCardModal}
        handler={() => setOpenGiftCardModal(false)}
      >
        <AddGiftCard
          setOpenGiftCardModal={setOpenGiftCardModal}
          formik={formik}
          selectedIndex={selectedIndex}
          handleGiftCardClose={handleGiftCardClose}
        />
      </Modal>
      <Modal
        open={openHoldOrderModal}
        handler={() => setOpenHoldOrderModal(false)}
      >
        <HoldOrder
          setOpenHoldOrderModal={setOpenHoldOrderModal}
          formik={formik}
          setSelectedCustomer={setSelectedCustomer}
          orderId={orderId}
        />
      </Modal>

      {/* Modal Component */}
      <Modal
        size="md"
        open={openEditModal}
        handler={() => setOpenEditModal(false)}
      >
        {selectedEditProduct && (
          <div className="flex flex-col w-full gap-y-5">
            <div className="flex items-center justify-between">
              <h5 className="text-lg font-bold text-info-500 xl:text-2xl lg:text-xl">
                {selectedEditProduct?.product_name}
              </h5>
              <IconButton
                size=""
                variant="text"
                className=""
                onClick={() => setOpenEditModal(false)}
              >
                <FontAwesomeIcon icon={faClose} fontSize={18} />
              </IconButton>
            </div>
            <div className="flex-grow overflow-y-auto border rounded-theme">
              <div className="flex items-center justify-between px-2 py-3 bg-secondary-50">
                <h5 className="pl-3 text-lg font-medium text-black">
                  {t("Variant")}
                </h5>
                <div className="text-center w-36">
                  <h5 className="text-lg font-medium text-black">
                    {t("Price")}
                  </h5>
                </div>
              </div>
              <div className="p-2">
                {selectedEditProduct?.variants_possibilities?.map(
                  (items, index) => (
                    <Fragment key={index}>
                      <div className="flex items-center justify-between">
                        <label className="flex items-center gap-3 cursor-pointer">
                          <Radio
                            className="w-6 h-6"
                            name="variant"
                            value={items?.sku}
                            label={items?.name}
                            labelProps={{
                              className: "text-info-700 text-base font-medium",
                            }}
                            checked={selectedVariant?.sku === items.sku}
                            onChange={() => handleVariantChange(items)}
                            disabled={formik?.values?.orderItems
                              .filter((_, index) => index !== selectedIndex) // Exclude the item at the selectedIndex
                              .some((orderItem) => orderItem.sku === items.sku)}
                          />
                        </label>
                        <div className="text-base font-medium text-center text-info-700 w-36">
                          {formatCurrencyWithNumberValue(items?.price)}
                        </div>
                      </div>
                      {index <
                        selectedEditProduct?.variants_possibilities?.length -
                        1 && <hr className="" />}
                    </Fragment>
                  )
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                type="button"
                className="text-sm font-semibold capitalize bg-primary-400 rounded-theme"
                onClick={() => {
                  // Destructure the necessary properties from the selected variant
                  const { price, sku, name } = selectedVariant;

                  // Create an updated item using the selected variant details
                  const updatedItem = {
                    ...formik.values.orderItems[selectedIndex],

                    selling_price: price,
                    sku: sku,
                    variant_name: name,
                  };

                  // Update the specific item in the orderItems array at the selected index
                  const updatedOrderItems = [...formik.values.orderItems];
                  updatedOrderItems[selectedIndex] = updatedItem;

                  // Set the updated array back to Formik field
                  formik.setFieldValue("orderItems", updatedOrderItems);

                  // Close the edit modal
                  setOpenEditModal(false);

                  // Log the updated order items array
                  console.log(formik.values.orderItems);
                }}
                disabled={!selectedVariant}
              >
                {t("Save changes")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CustomerOrderWrapper;
