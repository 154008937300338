import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerRequest,
  getSingleCustomerRequest,
  updateAdjustPointRequest,
  updateCustomerRequest,
} from "../../store/customer/actions";
import Input from "../common/Input";
import Loader from "../common/Loader";
import Modal from "../common/Modal";
import LoyaltyPointsHistory from "./LoyaltyPointsHistory";
import { customerState } from "../../store/customer/state";
import AdjustLoyaltyPoints from "./AdjustLoyaltyPoints";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
const CustomerForm = ({
  selectedCustomerId,
  setSelectedCustomerId,
  setOpenCustomerForm,
  searchCustomer
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    isSingleDetailLoading,
    singleCustomer,
    isUpdateLoading,
  } = useSelector((store) => ({
    isLoading: store?.customer?.createCustomer?.loading,
    isSingleDetailLoading: store?.customer?.getCustomerDetail?.loading,
    singleCustomer: store?.customer?.getCustomerDetail?.data,
    isUpdateLoading: store?.customer?.updateCustomer?.loading,
  }));

  console.log(searchCustomer, "searchCustomer........")

  const [openAdjustModal, setOpenAdjustModal] = useState(false);
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');

  const today = new Date().toISOString().split("T")[0];


  // which name add by user into search split it based on space

  useEffect(() => {
    const processCustomerName = (searchCustomer) => {
      // Split the name by spaces
      const nameParts = searchCustomer.trim().split(/\s+/);

      let first_name = '';
      let last_name = '';

      // Case 1: If there's only one word, assign it to first_name
      if (nameParts.length === 1) {
        first_name = nameParts[0];
      }
      // Case 2: If there are two words, assign the first to first_name and second to last_name
      else if (nameParts.length === 2) {
        first_name = nameParts[0];
        last_name = nameParts[1];
      }
      // Case 3: If there are more than two words, assign the first word to first_name and the rest to last_name
      else if (nameParts.length > 2) {
        first_name = nameParts[0];
        last_name = nameParts.slice(1).join(' '); // Join the remaining parts as last name
      }

      return { first_name, last_name };
    };

    const { first_name: processedFirstName, last_name: processedLastName } = processCustomerName(searchCustomer);

    // Update state with the processed values
    setSearchFirstName(processedFirstName);
    setSearchLastName(processedLastName);

    // Log the processed names for debugging
    console.log(processedFirstName, processedLastName, "Processed Names");

  }, [searchCustomer]); // Run effect when searchCustomer changes




  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(getSingleCustomerRequest({ customerId: selectedCustomerId }));
    }
  }, [selectedCustomerId, dispatch]);

  useEffect(() => {
    return () => {
      setSelectedCustomerId(null);
      dispatch(customerState({ key: "getCustomerDetail.data", value: null }));
    };
  }, [dispatch]);

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: singleCustomer?.first_name || searchFirstName || "",
          last_name: singleCustomer?.last_name || searchLastName || "",
          birthday: singleCustomer
            ? moment(singleCustomer?.birthday).format("YYYY-MM-DD")
            : "",
          email: singleCustomer?.email || "",
          phone: singleCustomer?.code + singleCustomer?.phone || "",
          code: singleCustomer?.code || "",
          notes: singleCustomer?.notes || "",
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required(t("Please enter first name")),
          last_name: Yup.string().required(t("Please enter last name")),
          birthday: Yup.date()
            .max(new Date(), t("Birthday cannot be in the future"))
            .required(t("Please enter birthdate")),
          email: Yup.string()
            .email(t("Invalid email address"))
            .required(t("Please enter email")),
          phone: Yup.string().required(t("Please enter phone number")),
          notes: Yup.string(),
        })}
        onSubmit={(values) => {
          const body = {
            ...values,
            phone: values.phone.slice(values.code.length - 1),
          };
          if (selectedCustomerId) {
            dispatch(
              updateCustomerRequest({
                customerId: selectedCustomerId,
                body,
                setModalClose: () => setOpenCustomerForm(false),
              })
            );
          } else {
            dispatch(
              createCustomerRequest({
                body,
                setModalClose: () => setOpenCustomerForm(false),
              })
            );
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
        }) => (
          console.log(errors, "errors..............."),
          (
            <Form
              onSubmit={handleSubmit}
              className="relative flex flex-col h-full gap-y-3"
            >
              {isSingleDetailLoading && (
                <div className="absolute z-50 flex items-center justify-center w-full h-full bg-white/70 backdrop-blur-sm">
                  <Loader />
                </div>
              )}
              <div className="flex items-center gap-x-4">
                <IconButton
                  type="button"
                  className="flex-shrink-0 border shadow-none bg-secondary-50 text-primary-400"
                  onClick={() => {
                    setOpenCustomerForm(false);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
                </IconButton>
                <h3 className="text-lg font-medium text-info-400">
                  {selectedCustomerId ? t("Edit Customer") : t("Add Customer")}
                </h3>
              </div>
              <div className="flex-grow pr-2 overflow-hidden overflow-y-auto">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Input
                      type={"text"}
                      className={"placeholder:text-sm placeholder:font-normal "}
                      label={t("First Name")}
                      name="first_name"
                      error={errors.first_name && touched.first_name}
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("First Name")}
                    />

                    <ErrorMessage
                      name="first_name"
                      component={"p"}
                      className="my-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <Input
                      type={"text"}
                      className={
                        "placeholder:text-sm placeholder:font-normal label:text-secondary-500"
                      }
                      label={t("Last Name")}
                      name="last_name"
                      error={errors.last_name && touched.last_name}
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("Last Name")}
                    />
                    <ErrorMessage
                      name="last_name"
                      component={"p"}
                      className="my-1 text-sm text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <label
                      className={`text-base_24 font-medium text-secondary-400`}
                    >
                      {t("Phone Number")}
                    </label>
                    <PhoneInput
                      country={"us"}
                      placeholder={t("Phone Number")}
                      value={values.phone}
                      name={"phone"}
                      error={errors.phone && touched.phone}
                      onChange={(value, data, event, formattedValue) => {
                        setFieldValue("code", `+${data.dialCode}`);
                        setFieldValue("phone", value);
                      }}
                      onBlur={() => setFieldTouched("phone", true)} // Mark the phone field as touched
                      inputProps={{
                        name: "phone", // Additional input props
                        autoFocus: false,
                        placeholder: t("Phone Number"), // Ensure it's set here as well
                      }}
                      style={{ paddingLeft: "1px" }}
                      containerClass="w-full"
                      inputClass="w-full px-3 py-2 border  rounded-md  
                          border-primary-400 focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-400"
                    />
                    <ErrorMessage
                      name="phone"
                      component={"p"}
                      className="text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <Input
                      type={"date"}
                      className={"pr-3"}
                      label={t("Birthday")}
                      name="birthday"
                      max={today}
                      value={values.birthday}
                      error={errors.birthday && touched.birthday}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="birthday"
                      component={"p"}
                      className="my-1 text-sm text-red-500"
                    />
                  </div>
                </div>
                <div className="my-4">
                  <Input
                    type={"email"}
                    placeholder={t("Email")}
                    className={"placeholder:text-sm placeholder:font-normal "}
                    label={t("Email")}
                    name="email"
                    value={values.email}
                    error={errors.email && touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="email"
                    component={"p"}
                    className="my-1 text-sm text-red-500"
                  />
                </div>
                {selectedCustomerId && (
                  <ul className="flex flex-col gap-y-4">
                    <li className="flex items-center justify-between">
                      <p className="font-medium text-secondary-500">
                        {t("Store Credit Balance")}
                      </p>
                      <p className="font-medium text-info-400">
                        {singleCustomer?.store_credit_balance.toFixed(2) || 0}
                      </p>
                    </li>
                    <li className="flex items-center justify-between">
                      <p className="font-medium text-secondary-500">
                        {t("Loyalty Points Balance")}
                      </p>
                      <p className="font-medium text-info-400">
                        {singleCustomer?.loyalty_points.toFixed(2) || 0}{" "}
                        {t("pts")}
                      </p>
                    </li>
                    <li className="flex items-center justify-between">
                      <p className="font-medium text-secondary-500">
                        {t("Loyalty Points Redeemable")}
                      </p>
                      <p className="font-medium text-info-400">
                        {singleCustomer?.redeemable_loyalty_points.toFixed(2) ||
                          0}{" "}
                        {t("pts")}
                      </p>
                    </li>
                  </ul>
                )}
                <div className="flex flex-col mt-4 gap-y-1">
                  <label
                    htmlFor=""
                    className={`lg:text-base_24 text-sm_18 font-medium text-secondary-400`}
                  >
                    {t("Notes")}
                  </label>
                  <textarea
                    id=""
                    rows="4"
                    className="placeholder:text-sm placeholder:font-normal bg-gray-100 border border-secondary-200 font-semibold text-info-400 rounded-theme p-2.5 outline-none focus:border-primary-400 focus:shadow transition-all duration-200"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Enter Notes")}
                  ></textarea>
                </div>
              </div>
              {selectedCustomerId && (
                <Fragment>
                  <div className="flex flex-col mb-2 gap-y-2">
                    <div className="flex items-center justify-between">
                      <h4 className="font-medium capitalize text-secondary-500">
                        {t("loyalty points")}
                      </h4>
                      <Button
                        size="sm"
                        type="button"
                        className="py-2 text-sm capitalize border bg-primary-150 border-primary-400 text-primary-400"
                        onClick={() => setOpenAdjustModal(true)}
                      >
                        {t("Adjust")}
                      </Button>
                    </div>
                  </div>

                  <LoyaltyPointsHistory
                    selectedCustomerId={selectedCustomerId}
                  />
                </Fragment>
              )}
              <Button
                type="submit"
                fullWidth
                className="flex items-center justify-center text-sm capitalize bg-primary-400 gap-x-2"
                disabled={isLoading || isUpdateLoading}
                loading={isLoading || isUpdateLoading}
              >
                {selectedCustomerId ? t("Save") : t("Add Customer")}
              </Button>
            </Form>
          )
        )}
      </Formik>
      <Modal open={openAdjustModal} handler={() => setOpenAdjustModal(false)}>
        <AdjustLoyaltyPoints
          singleCustomer={singleCustomer}
          selectedCustomerId={selectedCustomerId}
          setOpenAdjustModal={setOpenAdjustModal}
        />
      </Modal>
    </Fragment>
  );
};

export default CustomerForm;
