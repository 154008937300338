import { faPenToSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import Modal from "../common/Modal";
import { useDispatch } from "react-redux";
import {
  deleteVariantRequest,
  updateVariantRequest,
} from "../../store/inventory/variants/actions";
import { cloneDeep } from "lodash";
import Input from "../common/Input";
import { formatCurrencyWithNumberValue } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { ManageDeleteProductIcon } from "../svgs";

const VariantTable = ({
  item,
  index,
  addedVariantsList,
  formik,
  setAddedVariantsList,
  queryClient
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openEditValue, setOpenEditValue] = useState(false);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleEdit = (index) => {
    setOpenEditValue(true);
    setSelectedVariantIndex(index);
  };

  const handleSave = async (index) => {
    setAddedVariantsList(addedVariantsList);
    formik.setFieldValue("variants_possibilities", addedVariantsList);
    if (addedVariantsList[index]?.sku) {
      const body = {
        price: addedVariantsList[index]?.price,
        cost_price: addedVariantsList[index]?.cost_price,
        stock: addedVariantsList[index]?.stock,
      };
      await dispatch(
        updateVariantRequest({
          skuId: addedVariantsList[index].sku,
          body: body,
        })
      );


      // Invalidate the query to mark it as stale
      queryClient.invalidateQueries(["productsData"]);

      // Manually refetch to update the UI immediately
      queryClient.refetchQueries(["productsData"]);
    }
    setSelectedVariantIndex(null);
    setOpenEditValue(false);
  };

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    setAddedVariantsList((prevRows) => {
      // Create a copy of the previous rows
      const newRows = cloneDeep(prevRows);
      // Ensure the row exists before updating
      if (newRows[index]) {
        newRows[index][field] = value;
      }
      return newRows;
    });
  };

  /* -------------------------- handle delete variant ------------------------- */
  const handleDeleteVariant = async () => {
    const newArr = [...addedVariantsList];
    if (newArr[selectedVariantIndex].sku)
      await dispatch(
        deleteVariantRequest({ skuId: newArr[selectedVariantIndex].sku })
      );

    // Invalidate the query to mark it as stale
    queryClient.invalidateQueries(["productsData"]);

    // Manually refetch to update the UI immediately
    queryClient.refetchQueries(["productsData"]);

    newArr.splice(selectedVariantIndex, 1);
    setAddedVariantsList(newArr);
    formik.setFieldValue("variants_possibilities", newArr);
    setOpenDeleteModal(false);
    setSelectedVariantIndex(null);
  };

  return (
    <Fragment>
      <h3 className="col-span-4 text-base font-semibold text-info-400">
        {item?.name}
      </h3>
      <div className="col-span-2">
        {selectedVariantIndex === index && openEditValue ? (
          <Input
            type={"text"}
            value={item.price}
            onChange={(e) => handleChange(e, index, "price")}
          />
        ) : (
          <h3 className="text-base font-semibold text-info-400">
            {item?.price}
          </h3>
        )}
      </div>
      <div className="col-span-2">
        {selectedVariantIndex === index && openEditValue ? (
          <Input
            type={"text"}
            value={item.cost_price}
            onChange={(e) => handleChange(e, index, "cost_price")}
          />
        ) : (
          <h3 className="text-base font-semibold text-info-400">
            {item.cost_price}
          </h3>
        )}
      </div>
      <div className="col-span-2">
        {selectedVariantIndex === index && openEditValue ? (
          <Input
            type={"text"}
            value={item.stock}
            onChange={(e) => handleChange(e, index, "stock")}
          />
        ) : (
          <h3 className="text-base font-semibold text-info-400">
            {item.stock}
          </h3>
        )}
      </div>
      <div className="flex items-center col-span-2 gap-x-3">
        {selectedVariantIndex === index && openEditValue ? (
          <Button
            size="sm"
            type="button"
            className="bg-primary-400"
            onClick={() => handleSave(index)}
          >
            {t("Save")}
          </Button>
        ) : (
          <IconButton
            size="sm"
            type="button"
            variant="text"
            className="text-primary-400"
            onClick={() => handleEdit(index)}
          >
            <FontAwesomeIcon icon={faPenToSquare} fontSize={18} />
          </IconButton>
        )}
        <div className="w-0.5 h-4 bg-secondary-150"></div>
        <IconButton
          size="sm"
          type="button"
          variant="text"
          className="text-red-500"
          onClick={() => {
            setOpenDeleteModal(true);
            setSelectedVariantIndex(index);
          }}
        >
          {/* <FontAwesomeIcon icon={faTrashAlt} fontSize={18} /> */}
          <ManageDeleteProductIcon className="w-4 h-4" />
        </IconButton>
      </div>
      {index < addedVariantsList?.length - 1 && <hr className="col-span-12" />}
      <Modal
        open={openDeleteModal}
        handler={() => {
          setOpenDeleteModal(false);
          setSelectedVariantIndex(null);
        }}
      >
        <div className="flex flex-col ">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this Variant ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-base font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setOpenDeleteModal(false);
                setSelectedVariantIndex(null);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-base font-semibold text-red-500 capitalize bg-white border border-red-500"
              onClick={handleDeleteVariant}
            >
              {t("Yes, delete variant")}
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default VariantTable;
