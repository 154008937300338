import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { cloneDeep } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidePanel from "../../components/common/SidePanel";
import ManageAttribute from "../../components/inventory/ManageAttribute";
import ManageVariant from "../../components/inventory/ManageVariant";
import VariantTable from "../../components/inventory/VariantTable";
import { ListIcon, MenuBarSvg } from "../../components/svgs";
import { ProductContext } from "../../helpers/context/ProductContext";
import Layout from "../../layout";
import {
  createVariantRequest,
  setAllAttributes,
  setAttributes,
} from "../../store/inventory/variants/actions";
import {
  formatCurrencyWithNumberValue,
  formatCurrencyWithStringValue,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import Modal from "../../components/common/Modal";
import { useQueryClient } from "@tanstack/react-query";

const ProductVariant = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { formik, singleData, productId } = useContext(ProductContext);

  const { allAttributes, attributes } = useSelector((store) => ({
    allAttributes: store?.variant?.allAttributes,
    attributes: store?.variant?.attributes,
  }));

  const [openAttribute, setOpenAttribute] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [openVariantModal, setOpenVariantModal] = useState(false);
  const [deleteAttributeModal, setDeleteAttributeModal] = useState(false);
  const [deleteAttributeIndex, setDeleteAttributeIndex] = useState(false);

  const [addedVariantsList, setAddedVariantsList] = useState(
    formik.values.variants_possibilities || []
  );

  const handleSaveAttributes = () => {
    const allNewAttributes = cloneDeep(allAttributes);
    const findIndex = allNewAttributes.findIndex(
      (item) => item.attribute_name == selectedAttribute?.item?.attribute_name
    );
    if (findIndex !== -1) {
      allNewAttributes[findIndex] = attributes[0];
      dispatch(setAllAttributes(allNewAttributes));
    } else {
      const arr = [...allNewAttributes, ...attributes];
      dispatch(setAllAttributes(arr));
    }
    setOpenAttribute(false);
    setSelectedAttribute(null);
    dispatch(setAttributes([{ attribute_name: "", options: [{ val: "" }] }]));
  };

  const handleAddVariantValue = async (data) => {
    const newArr = [...addedVariantsList];
    newArr.push(data);
    setAddedVariantsList(newArr);
    formik.setFieldValue("variants_possibilities", newArr);
    if (singleData?._id)
      await dispatch(
        createVariantRequest({ productId: singleData?._id, body: data })
      );

    // Invalidate the query to mark it as stale
    queryClient.invalidateQueries(["productsData"]);

    // Manually refetch to update the UI immediately
    queryClient.refetchQueries(["productsData"]);
  };

  /* ------------------------- handle delete attribute ------------------------ */
  const handleDeleteAttribute = (index) => {
    const newArr = [...allAttributes];
    newArr.splice(index, 1);
    dispatch(setAllAttributes(newArr));
  };

  return (
    <Layout>
      <div className="xl:h-[calc(100vh-126px)] h-[calc(100vh-100px)] overflow-y-auto w-full">
        <div className="sticky top-0 z-10 flex items-center justify-between p-5 mb-6 bg-white rounded-theme">
          <div className="flex items-center gap-x-3">
            <IconButton
              type="button"
              variant="text"
              size="sm"
              className="-ml-2 text-primary-400"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} fontSize={18} />
            </IconButton>
            <p className="text-info-500 xl:text-xl lg:text-lg text-base font-semibold -mt-0.5">
              {t("Add New Variants")}
            </p>
          </div>
          {allAttributes?.length > 0 && (
            <Button
              type="button"
              className="flex items-center text-base font-semibold capitalize bg-white border text-primary-400 border-primary-400 gap-x-3"
              onClick={() => setOpenVariantModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} fontSize={18} />
              <p>{t("add new Variants")}</p>
            </Button>
          )}
        </div>
        <div className="p-5 bg-white border rounded-theme">
          <div className="flex gap-x-8">
            <div className="flex-shrink-0 w-60">
              <p className="text-lg font-semibold text-secondary-400">
                {t("Attributes")}
              </p>
            </div>
            <div className="grow">
              <p className="text-lg font-semibold text-secondary-400">
                {t("Option Values")}
              </p>
            </div>

            {/* <div className="">
              <p className="text-lg font-semibold text-secondary-400">
                {t("Manage")}
              </p>
            </div> */}
          </div>
          {allAttributes?.length > 0 && (
            <div className="flex flex-col my-5 gap-y-6">
              {allAttributes?.map((item, index) => (
                <Fragment key={index}>
                  {selectedAttribute?.index === index ? (
                    <ManageAttribute
                      attributes={attributes}
                      handleSaveAttributes={handleSaveAttributes}
                      allAttributes={allAttributes}
                      setOpenAttribute={setOpenAttribute}
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                    />
                  ) : (
                    <div className="flex pb-5 border-b gap-x-8 border-secondary-150">
                      <div className="flex items-center flex-shrink-0 gap-2 w-60">
                        <ListIcon />
                        <p className="text-lg font-medium text-info-400">
                          {item.attribute_name}
                        </p>
                      </div>
                      <div className="grow">
                        <div className="flex items-center gap-x-4 ">
                          {item.options?.map((opt, ind) => (
                            <p
                              key={ind}
                              className="px-4 py-3 text-base font-medium border rounded-theme border-secondary-200 text-info-400"
                            >
                              {opt.val}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-end flex-shrink-0 pb-1">
                        <Menu placement="bottom-end">
                          <MenuHandler>
                            <IconButton
                              type="button"
                              className="bg-white border border-secondary-200 text-info-400"
                            >
                              <MenuBarSvg />
                            </IconButton>
                          </MenuHandler>
                          <MenuList className="p-0">
                            <MenuItem
                              type="button"
                              className="active:bg-transparent text-info-400 text-base rounded hover:!bg-primary-400 hover:!text-white font-medium transition-all ease-in-out duration-200"
                              onClick={() => {
                                setSelectedAttribute({ index, item });
                                dispatch(
                                  setAttributes([
                                    {
                                      attribute_name: item.attribute_name,
                                      options: item.options,
                                    },
                                  ])
                                );
                              }}
                            >
                              {t("Edit")}
                            </MenuItem>
                            <MenuItem
                              type="button"
                              className="active:bg-transparent text-info-400 text-base rounded hover:!bg-primary-400 hover:!text-white font-medium transition-all ease-in-out duration-200"
                              onClick={() => {
                                setDeleteAttributeModal(true);
                                setDeleteAttributeIndex(index);
                              }}
                            >
                              {t("Delete")}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          )}
          {(allAttributes?.length === 0 || openAttribute) &&
            !selectedAttribute && (
              <ManageAttribute
                attributes={attributes}
                handleSaveAttributes={handleSaveAttributes}
                allAttributes={allAttributes}
                setOpenAttribute={setOpenAttribute}
                selectedAttribute={selectedAttribute}
                setSelectedAttribute={setSelectedAttribute}
              />
            )}
          {!selectedAttribute && !openAttribute && allAttributes?.length > 0 && (
            <Button
              size="sm"
              variant="text"
              type="button"
              className="flex items-center justify-start px-0 text-base font-semibold capitalize text-primary-400 gap-x-2 hover:bg-transparent"
              onClick={() => setOpenAttribute(true)}
            >
              <FontAwesomeIcon icon={faPlus} fontSize={18} />
              <p>{t("add new attributes")}</p>
            </Button>
          )}
        </div>
        {addedVariantsList?.length > 0 && (
          <div className="p-5 my-5 bg-white rounded-theme">
            <div className="flex flex-col gap-4">
              <div className="grid items-center grid-cols-12 gap-4">
                <h3 className="col-span-4 text-base font-semibold text-secondary-400">
                  {t("Variant")}
                </h3>
                <h3 className="col-span-2 text-base font-semibold text-secondary-400">
                  {formatCurrencyWithStringValue(t("Price"))}
                </h3>
                <h3 className="col-span-2 text-base font-semibold text-secondary-400">
                  {formatCurrencyWithStringValue(t("Cost Price"))}
                </h3>
                <h3 className="col-span-2 text-base font-semibold text-secondary-400">
                  {t("Stock")}
                </h3>
                <h3 className="col-span-2 text-base font-semibold text-secondary-400">
                  {t("Actions")}
                </h3>
              </div>
              <hr />
              <div className="grid items-center grid-cols-12 gap-4">
                {addedVariantsList?.map((item, index) => (
                  <VariantTable
                    item={item}
                    index={index}
                    addedVariantsList={addedVariantsList}
                    formik={formik}
                    setAddedVariantsList={setAddedVariantsList}
                    queryClient={queryClient}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <SidePanel
        open={openVariantModal}
        handler={() => setOpenVariantModal(false)}
        width="w-[550px]"
      >
        <ManageVariant
          openVariantModal={openVariantModal}
          handler={() => setOpenVariantModal(false)}
          allAttributes={allAttributes}
          handleAddVariantValue={handleAddVariantValue}
          addedVariantsList={addedVariantsList}
        />
      </SidePanel>

      {/* Dialog for delete */}
      <Modal
        open={deleteAttributeModal}
        handler={() => {
          setDeleteAttributeModal(false);
          setDeleteAttributeIndex(null);
        }}
      >
        <div className="flex flex-col">
          <p className="pb-3 text-lg font-semibold text-info-400">
            {t("Delete")}
          </p>
          <p className="text-base font-medium text-secondary-400 pb-7">
            {t("Are you sure to delete this Attribute ?")}
          </p>
          <div className="flex items-center justify-end gap-x-4">
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold capitalize border border-primary-400 bg-primary-400"
              onClick={() => {
                setDeleteAttributeModal(false);
                setDeleteAttributeIndex(null);
              }}
            >
              {t("No, Cancel")}
            </Button>
            <Button
              type="button"
              size="sm"
              className="text-sm font-semibold text-red-500 capitalize bg-white border border-red-500"
              onClick={() => {
                handleDeleteAttribute(deleteAttributeIndex);
                setDeleteAttributeModal(false);
                setDeleteAttributeIndex(null);
              }}
            >
              {t("Yes, delete Attribute")}
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default ProductVariant;
